import React, { useState } from 'react';
import { PencilIcon, PlusIcon } from 'lucide-react';

const PackageManagement = ({ subscriptionPackages, tenantSubscriptions, onRefresh }) => {
  const [editingPackage, setEditingPackage] = useState(null);
  const [editingSubscription, setEditingSubscription] = useState(null);
  const [isAddingPackage, setIsAddingPackage] = useState(false);
  const [isAddingSubscription, setIsAddingSubscription] = useState(false);

  const handlePackageSubmit = async (e, isEdit) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const packageData = {
      name: formData.get('name'),
      meeting_limit: parseInt(formData.get('meeting_limit')),
      is_trial: formData.get('is_trial') === 'true'
    };

    try {
      const url = isEdit 
        ? `https://cscvoiceadminapi.azurewebsites.net/api/subscription-packages/${editingPackage.id}`
        : 'https://cscvoiceadminapi.azurewebsites.net/api/subscription-packages';
      
      const response = await fetch(url, {
        method: isEdit ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(packageData)
      });

      if (!response.ok) {
        throw new Error('Failed to save package');
      }

      setEditingPackage(null);
      setIsAddingPackage(false);
      onRefresh();
    } catch (error) {
      console.error('Error saving package:', error);
    }
  };

  const handleSubscriptionSubmit = async (e, isEdit) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const subscriptionData = {
      tenant_id: formData.get('tenant_id'),
      package_id: parseInt(formData.get('package_id')),
      meetings_used: isEdit ? parseInt(formData.get('meetings_used')) : 0,
      start_date: formData.get('start_date'),
      end_date: formData.get('end_date')
    };

    try {
      const url = isEdit
        ? `https://cscvoiceadminapi.azurewebsites.net/api/tenant-subscriptions/${editingSubscription.id}`
        : 'https://cscvoiceadminapi.azurewebsites.net/api/tenant-subscriptions';

      const response = await fetch(url, {
        method: isEdit ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(subscriptionData)
      });

      if (!response.ok) {
        throw new Error('Failed to save subscription');
      }

      setEditingSubscription(null);
      setIsAddingSubscription(false);
      onRefresh();
    } catch (error) {
      console.error('Error saving subscription:', error);
    }
  };

  // Form components...
  const PackageForm = ({ initialData, isEdit }) => (
    <form onSubmit={(e) => handlePackageSubmit(e, isEdit)} className="space-y-4 p-4 bg-gray-50 rounded-lg">
      <div>
        <label className="block text-sm font-medium text-gray-700">Package Name</label>
        <input
          type="text"
          name="name"
          defaultValue={initialData?.name}
          className="mt-1 block w-full rounded-md border border-gray-300 p-2"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Meeting Limit</label>
        <input
          type="number"
          name="meeting_limit"
          defaultValue={initialData?.meeting_limit}
          className="mt-1 block w-full rounded-md border border-gray-300 p-2"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Trial Package</label>
        <select
          name="is_trial"
          defaultValue={initialData?.is_trial?.toString()}
          className="mt-1 block w-full rounded-md border border-gray-300 p-2"
        >
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </div>
      <div className="flex space-x-2">
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          {isEdit ? 'Update' : 'Create'} Package
        </button>
        <button
          type="button"
          onClick={() => isEdit ? setEditingPackage(null) : setIsAddingPackage(false)}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
        >
          Cancel
        </button>
      </div>
    </form>
  );

  const SubscriptionForm = ({ initialData, isEdit }) => (
    <form onSubmit={(e) => handleSubscriptionSubmit(e, isEdit)} className="space-y-4 p-4 bg-gray-50 rounded-lg">
      <div>
        <label className="block text-sm font-medium text-gray-700">Tenant ID</label>
        <input
          type="text"
          name="tenant_id"
          defaultValue={initialData?.tenant_id}
          className="mt-1 block w-full rounded-md border border-gray-300 p-2"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Package</label>
        <select
          name="package_id"
          defaultValue={initialData?.package_id}
          className="mt-1 block w-full rounded-md border border-gray-300 p-2"
          required
        >
          {subscriptionPackages.map(pkg => (
            <option key={pkg.id} value={pkg.id}>{pkg.name}</option>
          ))}
        </select>
      </div>
      {isEdit && (
        <div>
          <label className="block text-sm font-medium text-gray-700">Meetings Used</label>
          <input
            type="number"
            name="meetings_used"
            defaultValue={initialData?.meetings_used}
            className="mt-1 block w-full rounded-md border border-gray-300 p-2"
            required
          />
        </div>
      )}
      <div>
        <label className="block text-sm font-medium text-gray-700">Start Date</label>
        <input
          type="date"
          name="start_date"
          defaultValue={initialData?.start_date?.split('T')[0]}
          className="mt-1 block w-full rounded-md border border-gray-300 p-2"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">End Date</label>
        <input
          type="date"
          name="end_date"
          defaultValue={initialData?.end_date?.split('T')[0]}
          className="mt-1 block w-full rounded-md border border-gray-300 p-2"
          required
        />
      </div>
      <div className="flex space-x-2">
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          {isEdit ? 'Update' : 'Create'} Subscription
        </button>
        <button
          type="button"
          onClick={() => isEdit ? setEditingSubscription(null) : setIsAddingSubscription(false)}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
        >
          Cancel
        </button>
      </div>
    </form>
  );

  return (
    <div className="space-y-6">
      {/* Package Section */}
      <div className="bg-white rounded-lg shadow">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Subscription Packages</h2>
            <button
              onClick={() => setIsAddingPackage(true)}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center space-x-2 hover:bg-blue-600"
            >
              <PlusIcon size={16} />
              <span>Add Package</span>
            </button>
          </div>

          {isAddingPackage && <PackageForm isEdit={false} />}

          <div className="overflow-x-auto mt-4">
            <table className="min-w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Meeting Limit</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Trial</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Active Subscriptions</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {subscriptionPackages.map((pkg) => (
                  <tr key={pkg.id}>
                    {editingPackage?.id === pkg.id ? (
                      <td colSpan="5">
                        <PackageForm initialData={pkg} isEdit={true} />
                      </td>
                    ) : (
                      <>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{pkg.name}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{pkg.meeting_limit}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            pkg.is_trial ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
                          }`}>
                            {pkg.is_trial ? 'Yes' : 'No'}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{pkg.active_subscriptions}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <button
                            onClick={() => setEditingPackage(pkg)}
                            className="text-blue-600 hover:text-blue-900"
                          >
                            <PencilIcon size={16} />
                          </button>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Subscriptions Section */}
      <div className="bg-white rounded-lg shadow">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Tenant Subscriptions</h2>
            <button
              onClick={() => setIsAddingSubscription(true)}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center space-x-2 hover:bg-blue-600"
            >
              <PlusIcon size={16} />
              <span>Add Subscription</span>
            </button>
          </div>

          {isAddingSubscription && <SubscriptionForm isEdit={false} />}

          <div className="overflow-x-auto mt-4">
            <table className="min-w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tenant ID</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Package</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Meetings Used</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date Range</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {tenantSubscriptions.map((sub) => (
                  <tr key={sub.id}>
                    {editingSubscription?.id === sub.id ? (
                      <td colSpan="6">
                        <SubscriptionForm initialData={sub} isEdit={true} />
                      </td>
                    ) : (
                      <>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{sub.tenant_id}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{sub.package_name}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {sub.meetings_used} / {sub.meeting_limit}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {new Date(sub.start_date).toLocaleDateString()} - {new Date(sub.end_date).toLocaleDateString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            new Date(sub.end_date) > new Date() ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                          }`}>
                            {new Date(sub.end_date) > new Date() ? 'Active' : 'Expired'}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <button
                            onClick={() => setEditingSubscription(sub)}
                            className="text-blue-600 hover:text-blue-900"
                          >
                            <PencilIcon size={16} />
                          </button>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageManagement;