import React, { useState, useEffect } from 'react';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Loader2 } from 'lucide-react';

const AnalyticsDashboard = () => {
  const [activeTab, setActiveTab] = useState('user');
  const [analyticsData, setAnalyticsData] = useState({
    users: [],
    timeStats: [],
    performance: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        };

        const [userRes, timeRes, performanceRes] = await Promise.all([
          fetch('https://cscvoiceadminapi.azurewebsites.net/api/user-analytics', { headers }),
          fetch('https://cscvoiceadminapi.azurewebsites.net/api/time-analytics', { headers }),
          fetch('https://cscvoiceadminapi.azurewebsites.net/api/performance-analytics', { headers })
        ]);

        const [userData, timeData, performanceData] = await Promise.all([
          userRes.json(),
          timeRes.json(),
          performanceRes.json()
        ]);

        setAnalyticsData({
          users: Array.isArray(userData) ? userData : [],
          timeStats: Array.isArray(timeData) ? timeData : [],
          performance: Array.isArray(performanceData) ? performanceData : []
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching analytics:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchAnalytics();
  }, []);

  const renderUserAnalytics = () => (
    <div className="space-y-6">
      {analyticsData.users.length > 0 ? (
        <>
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-semibold mb-4">User Activity Overview</h3>
            <div className="h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={analyticsData.users}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="user_name" />
                  <YAxis yAxisId="left" orientation="left" />
                  <YAxis yAxisId="right" orientation="right" />
                  <Tooltip />
                  <Legend />
                  <Bar yAxisId="left" dataKey="total_meetings" fill="#8884d8" name="Total Meetings" />
                  <Bar yAxisId="right" dataKey="total_words" fill="#82ca9d" name="Total Words" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-semibold mb-4">User Details</h3>
            <div className="overflow-x-auto">
              <table className="min-w-full">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Meetings</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Words</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Avg. Confidence</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Languages Used</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Active</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {analyticsData.users.map((user) => (
                    <tr key={user.user_id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.user_name}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.total_meetings}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.total_words?.toLocaleString()}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <span className={`px-2 py-1 rounded-full ${
                          user.avg_confidence > 0.8 ? 'bg-green-100 text-green-800' :
                          user.avg_confidence > 0.6 ? 'bg-yellow-100 text-yellow-800' :
                          'bg-red-100 text-red-800'
                        }`}>
                          {(user.avg_confidence * 100).toFixed(1)}%
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.languages_used}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {new Date(user.last_active).toLocaleDateString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <div className="bg-white rounded-lg shadow p-6 text-center text-gray-500">
          No user analytics data available
        </div>
      )}
    </div>
  );

  const renderTimeAnalytics = () => (
    <div className="space-y-6">
      {analyticsData.timeStats.length > 0 ? (
        <>
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-semibold mb-4">Daily Activity Trends</h3>
            <div className="h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={analyticsData.timeStats}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis yAxisId="left" orientation="left" />
                  <YAxis yAxisId="right" orientation="right" />
                  <Tooltip />
                  <Legend />
                  <Line yAxisId="left" type="monotone" dataKey="meetings_count" stroke="#8884d8" name="Meetings" />
                  <Line yAxisId="right" type="monotone" dataKey="users_count" stroke="#82ca9d" name="Users" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-semibold mb-4">Daily Statistics</h3>
            <div className="overflow-x-auto">
              <table className="min-w-full">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Meetings</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Users</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Words</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Languages</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Avg. Confidence</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {analyticsData.timeStats.map((day) => (
                    <tr key={day.date}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {new Date(day.date).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{day.meetings_count}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{day.users_count}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{day.total_words?.toLocaleString()}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{day.languages_used}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {(day.avg_confidence * 100).toFixed(1)}%
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <div className="bg-white rounded-lg shadow p-6 text-center text-gray-500">
          No time analytics data available
        </div>
      )}
    </div>
  );

  const renderPerformanceAnalytics = () => (
    <div className="space-y-6">
      {analyticsData.performance.length > 0 ? (
        <>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
            <div className="bg-white rounded-lg shadow p-6">
              <h3 className="text-lg font-semibold mb-2">Average Confidence</h3>
              <p className="text-3xl font-bold text-blue-600">
                {(analyticsData.performance.reduce((acc, curr) => acc + curr.avg_confidence, 0) / 
                analyticsData.performance.length * 100).toFixed(1)}%
              </p>
            </div>

            <div className="bg-white rounded-lg shadow p-6">
              <h3 className="text-lg font-semibold mb-2">High Confidence Records</h3>
              <p className="text-3xl font-bold text-green-600">
                {analyticsData.performance.reduce((acc, curr) => acc + curr.high_confidence_records, 0)}
              </p>
            </div>

            <div className="bg-white rounded-lg shadow p-6">
              <h3 className="text-lg font-semibold mb-2">Avg Words per Speech</h3>
              <p className="text-3xl font-bold text-purple-600">
                {Math.round(analyticsData.performance.reduce((acc, curr) => 
                  acc + curr.avg_words_per_speech, 0) / analyticsData.performance.length)}
              </p>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-semibold mb-4">Confidence Score Trends</h3>
            <div className="h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={analyticsData.performance}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis domain={[0, 1]} tickFormatter={(value) => `${(value * 100).toFixed(0)}%`} />
                  <Tooltip formatter={(value) => `${(value * 100).toFixed(1)}%`} />
                  <Legend />
                  <Line type="monotone" dataKey="avg_confidence" stroke="#8884d8" name="Average Confidence" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-semibold mb-4">Confidence Distribution</h3>
            <div className="overflow-x-auto">
              <table className="min-w-full">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">High Confidence</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Medium Confidence</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Low Confidence</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Avg Words/Speech</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Max Words</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {analyticsData.performance.map((day) => (
                    <tr key={day.date}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {new Date(day.date).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-green-600">{day.high_confidence_records}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-yellow-600">{day.medium_confidence_records}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-red-600">{day.low_confidence_records}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {Math.round(day.avg_words_per_speech)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{day.max_words_in_speech}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
     ) : (
      <div className="bg-white rounded-lg shadow p-6 text-center text-gray-500">
        No performance analytics data available
      </div>
    )}
  </div>
);

if (loading) {
  return (
    <div className="flex justify-center items-center h-48">
      <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
    </div>
  );
}

if (error) {
  return (
    <div className="flex justify-center items-center h-48">
      <div className="text-red-500">Error: {error}</div>
    </div>
  );
}

return (
  <div className="space-y-6">
    <div className="border-b border-gray-200">
      <nav className="-mb-px flex space-x-8">
        <button
          onClick={() => setActiveTab('user')}
          className={`py-4 px-1 border-b-2 font-medium text-sm ${
            activeTab === 'user'
              ? 'border-blue-500 text-blue-600'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
          }`}
        >
          User Analytics
        </button>
        <button
          onClick={() => setActiveTab('time')}
          className={`py-4 px-1 border-b-2 font-medium text-sm ${
            activeTab === 'time'
              ? 'border-blue-500 text-blue-600'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
          }`}
        >
          Time Analytics
        </button>
        <button
          onClick={() => setActiveTab('performance')}
          className={`py-4 px-1 border-b-2 font-medium text-sm ${
            activeTab === 'performance'
              ? 'border-blue-500 text-blue-600'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
          }`}
        >
          Performance Analytics
        </button>
      </nav>
    </div>

    {activeTab === 'user' && renderUserAnalytics()}
    {activeTab === 'time' && renderTimeAnalytics()}
    {activeTab === 'performance' && renderPerformanceAnalytics()}
  </div>
);
};

export default AnalyticsDashboard;