import React from 'react';

const StatsCards = ({ stats }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-lg font-semibold text-center mb-2">Total Meetings</h2>
        <p className="text-3xl text-center font-bold">{stats?.total_meetings || 0}</p>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-lg font-semibold text-center mb-2">Total Users</h2>
        <p className="text-3xl text-center font-bold">{stats?.total_users || 0}</p>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-lg font-semibold text-center mb-2">Total Words</h2>
        <p className="text-3xl text-center font-bold">{(stats?.total_words || 0).toLocaleString()}</p>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-lg font-semibold text-center mb-2">Supported Languages</h2>
        <p className="text-3xl text-center font-bold">{stats?.supported_languages || 0}</p>
      </div>
    </div>
  );
};

export default StatsCards;