import React, { useState, useEffect, useCallback } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Loader2, LogOut } from 'lucide-react';
import { useAuth } from '../components/AuthContext';
import MeetingsTable from './MeetingsTable';
import AnalyticsDashboard from './AnalyticsDashboard';
import PackageManagement from './PackageManagement';
import StatsCards from './StatsCards';

const Dashboard = () => {
  const { handleLogout } = useAuth();
  const [activeTab, setActiveTab] = useState('dashboard');
  const [stats, setStats] = useState(null);
  const [meetings, setMeetings] = useState([]);
  const [languageStats, setLanguageStats] = useState([]);
  const [subscriptionPackages, setSubscriptionPackages] = useState([]);
  const [tenantSubscriptions, setTenantSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      const [statsRes, meetingsRes, langStatsRes, packagesRes, subscriptionsRes] = await Promise.all([
        fetch('https://cscvoiceadminapi.azurewebsites.net/api/dashboard-stats', { headers }),
        fetch('https://cscvoiceadminapi.azurewebsites.net/api/meetings-admin', { headers }),
        fetch('https://cscvoiceadminapi.azurewebsites.net/api/language-stats', { headers }),
        fetch('https://cscvoiceadminapi.azurewebsites.net/api/subscription-packages', { headers }),
        fetch('https://cscvoiceadminapi.azurewebsites.net/api/tenant-subscriptions', { headers })
      ]);

      const [statsData, meetingsData, langStatsData, packagesData, subscriptionsData] = await Promise.all([
        statsRes.json(),
        meetingsRes.json(),
        langStatsRes.json(),
        packagesRes.json(),
        subscriptionsRes.json()
      ]);

      setStats(statsData);
      setMeetings(Array.isArray(meetingsData) ? meetingsData : []);
      setLanguageStats(Array.isArray(langStatsData) ? langStatsData : []);
      setSubscriptionPackages(Array.isArray(packagesData) ? packagesData : []);
      setTenantSubscriptions(Array.isArray(subscriptionsData) ? subscriptionsData : []);
      setLoading(false);
    } catch (error) {
      if (error.response?.status === 401) {
        handleLogout();
      }
      console.error('Error fetching data:', error);
      setError(error.message);
      setLoading(false);
    }
  }, [handleLogout]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-red-500">Error: {error}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <h1 className="text-xl font-bold">CSC Voice AI Admin Dashboard</h1>
            <button
              onClick={handleLogout}
              className="flex items-center space-x-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md"
            >
              <LogOut size={16} />
              <span>Logout</span>
            </button>
          </div>
        </div>
      </div>

      <div className="p-6 max-w-7xl mx-auto">
        <div className="mb-6 border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            <button
              onClick={() => setActiveTab('dashboard')}
              className={`py-4 px-1 border-b-2 font-medium text-sm ${
                activeTab === 'dashboard'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Dashboard
            </button>
            <button
              onClick={() => setActiveTab('analytics')}
              className={`py-4 px-1 border-b-2 font-medium text-sm ${
                activeTab === 'analytics'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Analytics
            </button>
            <button
              onClick={() => setActiveTab('packages')}
              className={`py-4 px-1 border-b-2 font-medium text-sm ${
                activeTab === 'packages'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Package Management
            </button>
          </nav>
        </div>

        {activeTab === 'dashboard' && (
          <div className="space-y-6">
            <StatsCards stats={stats} />
            {languageStats.length > 0 && (
              <div className="bg-white rounded-lg shadow p-6">
                <h2 className="text-xl font-semibold mb-4">Language Usage Statistics</h2>
                <div className="h-[400px]">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={languageStats}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="language_name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="total_words" stroke="#8884d8" name="Total Words" />
                      <Line type="monotone" dataKey="meeting_count" stroke="#82ca9d" name="Meeting Count" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            )}
            <MeetingsTable meetings={meetings} />
          </div>
        )}
        {activeTab === 'analytics' && <AnalyticsDashboard />}
        {activeTab === 'packages' && (
          <PackageManagement 
            subscriptionPackages={subscriptionPackages}
            tenantSubscriptions={tenantSubscriptions}
            onRefresh={fetchData}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;